<template>
  <div class="p-3 bg-white">
    <div class="d-flex align-items-center border-bottom pb-2 mb-4">
      <a class="fs-18 txt-grey-600" @click.prevent="backToMdtDetail">
        {{ room ? room.name : "" }}
        <span class="spinner-border" role="status" v-if="!room">
          <span class="sr-only">Loading...</span>
        </span>
      </a>
      <div v-if="session">
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="mx-2"
        >
          <path
            d="M8.29492 17.09L12.8749 12.5L8.29492 7.91L9.70492 6.5L15.7049 12.5L9.70492 18.5L8.29492 17.09Z"
            fill="#667085"
          />
        </svg>
        <a href="javascript:;" class="fs-18 txt-grey-900 mr-2">
          Ngày {{ formatDate(session.date) }} ({{
          (session.cases && session.cases.length) || 0
          }})
        </a>
        <button
          class="btn bg-pri text-white fs-14 fw-600"
          type="button"
          @click="goToRoom()"
          v-if="!isPass(session.date)"
        >
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="mr-2"
            v-if="!isPlayer"
          >
            <path
              d="M9.16699 6.33333L8.00033 7.5L10.167 9.66667H1.66699V11.3333H10.167L8.00033 13.5L9.16699 14.6667L13.3337 10.5L9.16699 6.33333ZM16.667 16.3333H10.0003V18H16.667C17.5837 18 18.3337 17.25 18.3337 16.3333V4.66667C18.3337 3.75 17.5837 3 16.667 3H10.0003V4.66667H16.667V16.3333Z"
              fill="white"
            />
          </svg>
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            v-if="isPlayer"
          >
            <path
              d="M10.0003 2.16602C5.40033 2.16602 1.66699 5.89935 1.66699 10.4993C1.66699 15.0993 5.40033 18.8327 10.0003 18.8327C14.6003 18.8327 18.3337 15.0993 18.3337 10.4993C18.3337 5.89935 14.6003 2.16602 10.0003 2.16602ZM8.33366 14.2493V6.74935L13.3337 10.4993L8.33366 14.2493Z"
              fill="white"
            />
          </svg>
          {{ isPlayer ? "Bắt đầu" : "Vào phòng" }}
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-10">
        <div class="input-group radius-12 border">
          <div class="input-group-text bg-white radius-12 border-0">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.7104 20.2894L18.0004 16.6094C19.4405 14.8138 20.1379 12.5347 19.9492 10.2407C19.7605 7.94672 18.7001 5.8122 16.9859 4.27606C15.2718 2.73992 13.0342 1.91893 10.7333 1.98189C8.43243 2.04485 6.24311 2.98698 4.61553 4.61456C2.98795 6.24214 2.04582 8.43145 1.98286 10.7323C1.9199 13.0332 2.7409 15.2708 4.27704 16.9849C5.81318 18.6991 7.94769 19.7595 10.2417 19.9482C12.5357 20.1369 14.8148 19.4395 16.6104 17.9994L20.2904 21.6794C20.3834 21.7731 20.494 21.8475 20.6158 21.8983C20.7377 21.9491 20.8684 21.9752 21.0004 21.9752C21.1324 21.9752 21.2631 21.9491 21.385 21.8983C21.5068 21.8475 21.6174 21.7731 21.7104 21.6794C21.8906 21.493 21.9914 21.2438 21.9914 20.9844C21.9914 20.7251 21.8906 20.4759 21.7104 20.2894ZM11.0004 17.9994C9.61592 17.9994 8.26255 17.5889 7.1114 16.8197C5.96026 16.0505 5.06305 14.9573 4.53324 13.6782C4.00342 12.3991 3.8648 10.9917 4.1349 9.63378C4.40499 8.27592 5.07168 7.02864 6.05065 6.04967C7.02961 5.0707 8.27689 4.40402 9.63476 4.13392C10.9926 3.86382 12.4001 4.00245 13.6792 4.53226C14.9583 5.06207 16.0515 5.95928 16.8207 7.11042C17.5899 8.26157 18.0004 9.61495 18.0004 10.9994C18.0004 12.8559 17.2629 14.6364 15.9501 15.9492C14.6374 17.2619 12.8569 17.9994 11.0004 17.9994Z"
                fill="#475467"
              />
            </svg>
          </div>
          <input type="text" class="form-control bg-white radius-12" v-model="search" />
        </div>
      </div>
      <div class="col-2">
        <button type="button" class="btn bg-pri-50 border bd-pri h-100" @click="gotoPrint">
          <svg
            width="18"
            height="16"
            viewBox="0 0 18 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.8332 4.66667H13.9998V0.5H3.99984V4.66667H3.1665C1.78317 4.66667 0.666504 5.78333 0.666504 7.16667V12.1667H3.99984V15.5H13.9998V12.1667H17.3332V7.16667C17.3332 5.78333 16.2165 4.66667 14.8332 4.66667ZM5.6665 2.16667H12.3332V4.66667H5.6665V2.16667ZM12.3332 13.8333H5.6665V10.5H12.3332V13.8333ZM13.9998 10.5V8.83333H3.99984V10.5H2.33317V7.16667C2.33317 6.70833 2.70817 6.33333 3.1665 6.33333H14.8332C15.2915 6.33333 15.6665 6.70833 15.6665 7.16667V10.5H13.9998Z"
              fill="#20419B"
            />
            <path
              d="M13.9998 8.41667C14.4601 8.41667 14.8332 8.04357 14.8332 7.58333C14.8332 7.1231 14.4601 6.75 13.9998 6.75C13.5396 6.75 13.1665 7.1231 13.1665 7.58333C13.1665 8.04357 13.5396 8.41667 13.9998 8.41667Z"
              fill="#20419B"
            />
          </svg>
          <span class="txt-pri fs-14 fw-600">In</span>
        </button>
      </div>
    </div>
    <div class="mb-3">
      <div class="table-responsive">
        <table class="table table-vcenter table-mobile-md card-table">
          <thead>
            <tr>
              <th>Workspace</th>
              <th>Bệnh nhân</th>
              <th>Chẩn đoán</th>
              <th>Thời gian đăng ký</th>
              <th>Bác sĩ phụ trách</th>
              <th>Trạng thái</th>
              <th>BB Hội Chẩn</th>
              <th>TQ Mổ</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="7" class="pt-4 cursor-pointer" @click="show_ts = !show_ts">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="mr-2"
                  :class="!show_ts ? 'upsidedown' : ''"
                >
                  <path d="M7 9.5L12 14.5L17 9.5H7Z" fill="#1D2939" />
                </svg>
                <span class="fs-16 fw-600 mb-0 txt-grey-900">Thông qua mổ</span>
              </td>
            </tr>
          </tbody>
          <tbody v-if="session" v-show="show_ts">
            <tr
              v-for="d in tsDiseases"
              :key="d.id"
              @click="goToMdtDisease(d, $event)"
              class="cursor-pointer"
              :set="[clinic = d.clinic]"
            >
              <td data-label="Workspace" class="border-bottom">
                <p class="fs-14 fw-600 txt-grey-900 mb-0">{{ clinic && clinic.name }}</p>
              </td>
              <td data-label="Bệnh nhân" class="border-bottom">
                <p class="fs-14 fw-600 txt-grey-900 mb-0">{{ d.patient_name }}</p>
                <p class="fs-12 txt-grey-600 mb-0">
                  ({{ mdtUtils.getYearOrders(d.patient_birthday)
                  }}{{ d.patient_address ? " - " + d.patient_address : "" }})
                </p>
              </td>
              <td data-label="Chẩn đoán" class="border-bottom">
                <div class="fs-14 fw-500 txt-grey-900 mb-0" v-html="patientDiagnostic(d)"></div>
              </td>
              <td data-label="Thời gian đăng ký" class="border-bottom">
                <p class="fs-14 fw-500 txt-grey-700 mb-0">{{ formatDateDMYHm(d.created_at) }}</p>
              </td>
              <td data-label="Bác sĩ phụ trách" class="border-bottom">
                <p
                  class="fs-14 fw-500 txt-grey-700 mb-0"
                >{{ d.mdt_room_disease.doctor && d.mdt_room_disease.doctor.name }}</p>
              </td>
              <td
                data-label="Trạng thái"
                :set="(sttClass = mdtUtils.getStatusPublicMdtCase(d.status))"
                class="border-bottom"
              >
                <p class="fs-16 fw-500 mb-0" :class="sttClass.class">{{ sttClass.text }}</p>
              </td>
              <td data-label="BB Hội Chẩn" class="border-bottom">
                <div class="flex space-x-2">
                  <!-- <svg
                    width="33"
                    height="32"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    @click="onStartToRoom(d.id, $event)"
                    v-if="!isPass(d.mdt_date)"
                  >
                    <path
                      d="M20 5C11.72 5 5 11.72 5 20C5 28.28 11.72 35 20 35C28.28 35 35 28.28 35 20C35 11.72 28.28 5 20 5ZM17 26.75V13.25L26 20L17 26.75Z"
                      fill="#20409B"
                    />
                  </svg>-->
                  <template v-if="d.concluding && d.concluding.pdf">
                    <svg
                      width="33"
                      height="32"
                      viewBox="0 0 33 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="cursor-pointer"
                      @click="open(d.concluding.pdf, $event)"
                    >
                      <path
                        d="M16.4997 11.4167C19.658 11.4167 22.4747 13.1917 23.8497 16C22.4747 18.8083 19.6663 20.5833 16.4997 20.5833C13.333 20.5833 10.5247 18.8083 9.14967 16C10.5247 13.1917 13.3413 11.4167 16.4997 11.4167ZM16.4997 9.75C12.333 9.75 8.77467 12.3417 7.33301 16C8.77467 19.6583 12.333 22.25 16.4997 22.25C20.6663 22.25 24.2247 19.6583 25.6663 16C24.2247 12.3417 20.6663 9.75 16.4997 9.75ZM16.4997 13.9167C17.6497 13.9167 18.583 14.85 18.583 16C18.583 17.15 17.6497 18.0833 16.4997 18.0833C15.3497 18.0833 14.4163 17.15 14.4163 16C14.4163 14.85 15.3497 13.9167 16.4997 13.9167ZM16.4997 12.25C14.433 12.25 12.7497 13.9333 12.7497 16C12.7497 18.0667 14.433 19.75 16.4997 19.75C18.5663 19.75 20.2497 18.0667 20.2497 16C20.2497 13.9333 18.5663 12.25 16.4997 12.25Z"
                        fill="#20409B"
                      />
                      <rect
                        x="1.25"
                        y="0.75"
                        width="30.5"
                        height="30.5"
                        rx="7.25"
                        stroke="#20419B"
                        stroke-width="1.5"
                      />
                    </svg>
                    <svg
                      width="33"
                      height="32"
                      viewBox="0 0 33 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="cursor-pointer"
                      @click="print(d.concluding.pdf, $event)"
                    >
                      <path
                        d="M22.3337 12.6667H21.5003V8.5H11.5003V12.6667H10.667C9.28366 12.6667 8.16699 13.7833 8.16699 15.1667V20.1667H11.5003V23.5H21.5003V20.1667H24.8337V15.1667C24.8337 13.7833 23.717 12.6667 22.3337 12.6667ZM13.167 10.1667H19.8337V12.6667H13.167V10.1667ZM19.8337 21.8333H13.167V18.5H19.8337V21.8333ZM21.5003 18.5V16.8333H11.5003V18.5H9.83366V15.1667C9.83366 14.7083 10.2087 14.3333 10.667 14.3333H22.3337C22.792 14.3333 23.167 14.7083 23.167 15.1667V18.5H21.5003Z"
                        fill="#20409B"
                      />
                      <path
                        d="M21.5003 16.4167C21.9606 16.4167 22.3337 16.0436 22.3337 15.5833C22.3337 15.1231 21.9606 14.75 21.5003 14.75C21.0401 14.75 20.667 15.1231 20.667 15.5833C20.667 16.0436 21.0401 16.4167 21.5003 16.4167Z"
                        fill="#20409B"
                      />
                      <rect
                        x="1.25"
                        y="0.75"
                        width="30.5"
                        height="30.5"
                        rx="7.25"
                        stroke="#20419B"
                        stroke-width="1.5"
                      />
                    </svg>
                  </template>
                </div>
              </td>
              <td data-label="TQ Mổ" class="border-bottom">
                <div class="d-flex" v-if="d.operation && d.operation.pdf">
                  <svg
                    width="33"
                    height="32"
                    viewBox="0 0 33 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-2 cursor-pointer"
                    @click="open(d.operation.pdf, $event)"
                  >
                    <path
                      d="M16.4997 11.4167C19.658 11.4167 22.4747 13.1917 23.8497 16C22.4747 18.8083 19.6663 20.5833 16.4997 20.5833C13.333 20.5833 10.5247 18.8083 9.14967 16C10.5247 13.1917 13.3413 11.4167 16.4997 11.4167ZM16.4997 9.75C12.333 9.75 8.77467 12.3417 7.33301 16C8.77467 19.6583 12.333 22.25 16.4997 22.25C20.6663 22.25 24.2247 19.6583 25.6663 16C24.2247 12.3417 20.6663 9.75 16.4997 9.75ZM16.4997 13.9167C17.6497 13.9167 18.583 14.85 18.583 16C18.583 17.15 17.6497 18.0833 16.4997 18.0833C15.3497 18.0833 14.4163 17.15 14.4163 16C14.4163 14.85 15.3497 13.9167 16.4997 13.9167ZM16.4997 12.25C14.433 12.25 12.7497 13.9333 12.7497 16C12.7497 18.0667 14.433 19.75 16.4997 19.75C18.5663 19.75 20.2497 18.0667 20.2497 16C20.2497 13.9333 18.5663 12.25 16.4997 12.25Z"
                      fill="#20409B"
                    />
                    <rect
                      x="1.25"
                      y="0.75"
                      width="30.5"
                      height="30.5"
                      rx="7.25"
                      stroke="#20419B"
                      stroke-width="1.5"
                    />
                  </svg>
                  <svg
                    width="33"
                    height="32"
                    viewBox="0 0 33 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="cursor-pointer"
                    @click="print(d.operation.pdf, $event)"
                  >
                    <path
                      d="M22.3337 12.6667H21.5003V8.5H11.5003V12.6667H10.667C9.28366 12.6667 8.16699 13.7833 8.16699 15.1667V20.1667H11.5003V23.5H21.5003V20.1667H24.8337V15.1667C24.8337 13.7833 23.717 12.6667 22.3337 12.6667ZM13.167 10.1667H19.8337V12.6667H13.167V10.1667ZM19.8337 21.8333H13.167V18.5H19.8337V21.8333ZM21.5003 18.5V16.8333H11.5003V18.5H9.83366V15.1667C9.83366 14.7083 10.2087 14.3333 10.667 14.3333H22.3337C22.792 14.3333 23.167 14.7083 23.167 15.1667V18.5H21.5003Z"
                      fill="#20409B"
                    />
                    <path
                      d="M21.5003 16.4167C21.9606 16.4167 22.3337 16.0436 22.3337 15.5833C22.3337 15.1231 21.9606 14.75 21.5003 14.75C21.0401 14.75 20.667 15.1231 20.667 15.5833C20.667 16.0436 21.0401 16.4167 21.5003 16.4167Z"
                      fill="#20409B"
                    />
                    <rect
                      x="1.25"
                      y="0.75"
                      width="30.5"
                      height="30.5"
                      rx="7.25"
                      stroke="#20419B"
                      stroke-width="1.5"
                    />
                  </svg>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td colspan="7" class="pt-4 cursor-pointer" @click="show_mdt = !show_mdt">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="mr-2"
                  :class="!show_mdt ? 'upsidedown' : ''"
                >
                  <path d="M7 9.5L12 14.5L17 9.5H7Z" fill="#1D2939" />
                </svg>
                <span class="fs-16 fw-600 mb-0 txt-grey-900">Hội chẩn</span>
              </td>
            </tr>
          </tbody>
          <tbody v-if="session" v-show="show_mdt">
            <tr
              v-for="d in mdtDiseases"
              :key="d.id"
              @click="goToMdtDisease(d, $event)"
              class="cursor-pointer"
            >
              <td data-label="Workspace" class="border-bottom">
                <p class="fs-14 fw-600 txt-grey-900 mb-0">{{ clinic && clinic.name }}</p>
              </td>
              <td data-label="Bệnh nhân" class="border-bottom">
                <p class="fs-14 fw-600 txt-grey-900 mb-0">{{ d.patient_name }}</p>
                <p class="fs-12 txt-grey-600 mb-0">
                  ({{ mdtUtils.getYearOrders(d.patient_birthday)
                  }}{{ d.patient_address ? " - " + d.patient_address : "" }})
                </p>
              </td>
              <td data-label="Chẩn đoán" class="border-bottom">
                <div class="fs-14 fw-500 txt-grey-900 mb-0" v-html="patientDiagnostic(d)"></div>
              </td>
              <td data-label="Thời gian đăng ký" class="border-bottom">
                <p class="fs-14 fw-500 txt-grey-700 mb-0">{{ formatDateDMYHm(d.created_at) }}</p>
              </td>
              <td data-label="Bác sĩ phụ trách" class="border-bottom">
                <p
                  class="fs-14 fw-500 txt-grey-700 mb-0"
                >{{ d.mdt_room_disease.doctor && d.mdt_room_disease.doctor.name }}</p>
              </td>
              <td
                data-label="Trạng thái"
                :set="(sttClass = mdtUtils.getStatusDisease(d.status))"
                class="border-bottom"
              >
                <p class="fs-16 fw-500 mb-0" :class="sttClass.class">{{ sttClass.text }}</p>
              </td>
              <td data-label="BB Hội Chẩn" class="border-bottom">
                <div class="flex space-x-2">
                  <!-- <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      @click="onStartToRoom(d.id, $event)"
                      v-if="!isPass(d.mdt_date)"
                  >
                    <path
                        d="M20 5C11.72 5 5 11.72 5 20C5 28.28 11.72 35 20 35C28.28 35 35 28.28 35 20C35 11.72 28.28 5 20 5ZM17 26.75V13.25L26 20L17 26.75Z"
                        fill="#20409B"
                    />
                  </svg>-->
                  <template v-if="d.concluding && d.concluding.pdf">
                    <svg
                      width="33"
                      height="32"
                      viewBox="0 0 33 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="cursor-pointer"
                      @click="open(d.concluding.pdf, $event)"
                    >
                      <path
                        d="M16.4997 11.4167C19.658 11.4167 22.4747 13.1917 23.8497 16C22.4747 18.8083 19.6663 20.5833 16.4997 20.5833C13.333 20.5833 10.5247 18.8083 9.14967 16C10.5247 13.1917 13.3413 11.4167 16.4997 11.4167ZM16.4997 9.75C12.333 9.75 8.77467 12.3417 7.33301 16C8.77467 19.6583 12.333 22.25 16.4997 22.25C20.6663 22.25 24.2247 19.6583 25.6663 16C24.2247 12.3417 20.6663 9.75 16.4997 9.75ZM16.4997 13.9167C17.6497 13.9167 18.583 14.85 18.583 16C18.583 17.15 17.6497 18.0833 16.4997 18.0833C15.3497 18.0833 14.4163 17.15 14.4163 16C14.4163 14.85 15.3497 13.9167 16.4997 13.9167ZM16.4997 12.25C14.433 12.25 12.7497 13.9333 12.7497 16C12.7497 18.0667 14.433 19.75 16.4997 19.75C18.5663 19.75 20.2497 18.0667 20.2497 16C20.2497 13.9333 18.5663 12.25 16.4997 12.25Z"
                        fill="#20409B"
                      />
                      <rect
                        x="1.25"
                        y="0.75"
                        width="30.5"
                        height="30.5"
                        rx="7.25"
                        stroke="#20419B"
                        stroke-width="1.5"
                      />
                    </svg>
                    <svg
                      width="33"
                      height="32"
                      viewBox="0 0 33 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="cursor-pointer"
                      @click="print(d.concluding.pdf, $event)"
                    >
                      <path
                        d="M22.3337 12.6667H21.5003V8.5H11.5003V12.6667H10.667C9.28366 12.6667 8.16699 13.7833 8.16699 15.1667V20.1667H11.5003V23.5H21.5003V20.1667H24.8337V15.1667C24.8337 13.7833 23.717 12.6667 22.3337 12.6667ZM13.167 10.1667H19.8337V12.6667H13.167V10.1667ZM19.8337 21.8333H13.167V18.5H19.8337V21.8333ZM21.5003 18.5V16.8333H11.5003V18.5H9.83366V15.1667C9.83366 14.7083 10.2087 14.3333 10.667 14.3333H22.3337C22.792 14.3333 23.167 14.7083 23.167 15.1667V18.5H21.5003Z"
                        fill="#20409B"
                      />
                      <path
                        d="M21.5003 16.4167C21.9606 16.4167 22.3337 16.0436 22.3337 15.5833C22.3337 15.1231 21.9606 14.75 21.5003 14.75C21.0401 14.75 20.667 15.1231 20.667 15.5833C20.667 16.0436 21.0401 16.4167 21.5003 16.4167Z"
                        fill="#20409B"
                      />
                      <rect
                        x="1.25"
                        y="0.75"
                        width="30.5"
                        height="30.5"
                        rx="7.25"
                        stroke="#20419B"
                        stroke-width="1.5"
                      />
                    </svg>
                  </template>
                </div>
              </td>
              <td data-label="TQ Mổ" class="border-bottom">
                <div class="d-flex" v-if="d.operation && d.operation.pdf">
                  <svg
                    width="33"
                    height="32"
                    viewBox="0 0 33 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-2 cursor-pointer"
                    @click="open(d.operation.pdf, $event)"
                  >
                    <path
                      d="M16.4997 11.4167C19.658 11.4167 22.4747 13.1917 23.8497 16C22.4747 18.8083 19.6663 20.5833 16.4997 20.5833C13.333 20.5833 10.5247 18.8083 9.14967 16C10.5247 13.1917 13.3413 11.4167 16.4997 11.4167ZM16.4997 9.75C12.333 9.75 8.77467 12.3417 7.33301 16C8.77467 19.6583 12.333 22.25 16.4997 22.25C20.6663 22.25 24.2247 19.6583 25.6663 16C24.2247 12.3417 20.6663 9.75 16.4997 9.75ZM16.4997 13.9167C17.6497 13.9167 18.583 14.85 18.583 16C18.583 17.15 17.6497 18.0833 16.4997 18.0833C15.3497 18.0833 14.4163 17.15 14.4163 16C14.4163 14.85 15.3497 13.9167 16.4997 13.9167ZM16.4997 12.25C14.433 12.25 12.7497 13.9333 12.7497 16C12.7497 18.0667 14.433 19.75 16.4997 19.75C18.5663 19.75 20.2497 18.0667 20.2497 16C20.2497 13.9333 18.5663 12.25 16.4997 12.25Z"
                      fill="#20409B"
                    />
                    <rect
                      x="1.25"
                      y="0.75"
                      width="30.5"
                      height="30.5"
                      rx="7.25"
                      stroke="#20419B"
                      stroke-width="1.5"
                    />
                  </svg>
                  <svg
                    width="33"
                    height="32"
                    viewBox="0 0 33 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="cursor-pointer"
                    @click="print(d.operation.pdf, $event)"
                  >
                    <path
                      d="M22.3337 12.6667H21.5003V8.5H11.5003V12.6667H10.667C9.28366 12.6667 8.16699 13.7833 8.16699 15.1667V20.1667H11.5003V23.5H21.5003V20.1667H24.8337V15.1667C24.8337 13.7833 23.717 12.6667 22.3337 12.6667ZM13.167 10.1667H19.8337V12.6667H13.167V10.1667ZM19.8337 21.8333H13.167V18.5H19.8337V21.8333ZM21.5003 18.5V16.8333H11.5003V18.5H9.83366V15.1667C9.83366 14.7083 10.2087 14.3333 10.667 14.3333H22.3337C22.792 14.3333 23.167 14.7083 23.167 15.1667V18.5H21.5003Z"
                      fill="#20409B"
                    />
                    <path
                      d="M21.5003 16.4167C21.9606 16.4167 22.3337 16.0436 22.3337 15.5833C22.3337 15.1231 21.9606 14.75 21.5003 14.75C21.0401 14.75 20.667 15.1231 20.667 15.5833C20.667 16.0436 21.0401 16.4167 21.5003 16.4167Z"
                      fill="#20409B"
                    />
                    <rect
                      x="1.25"
                      y="0.75"
                      width="30.5"
                      height="30.5"
                      rx="7.25"
                      stroke="#20419B"
                      stroke-width="1.5"
                    />
                  </svg>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
import mdtUtils from '../../utils/mdtUtils'
import moment from 'moment'
export default {
  name: 'OrganizationSessionDetail',
  data () {
    return {
      room: null,
      session: null,
      search: '',
      mdtUtils,
      url_pdf: '',
      show_ts: true,
      show_mdt: true,
      usr_member: {},
      user_id: null
    }
  },
  mounted () {
    this.user_id = appUtils.getLocalUser()?.id
    if (this.$route.params.id) {
      this.showSession()
    }
  },
  computed: {
    mdtRoomDiseases () {
      if (!this.session) {
        return []
      }
      return this.session.cases.map(e => ({
        caseId: e.id,
        ...e.mdt_room_disease
      }))
    },
    filteredAndSorted () {
      // function to compare names
      function compare (a, b) {
        if (a.name < b.name) return -1
        if (a.name > b.name) return 1
        return 0
      }
      let search_string = appUtils.removeVietnameseTones(
        this.search.toLowerCase()
      )
      if (this.session && this.session.cases.length) {
        const result = this.session.cases
          .filter(d => {
            return (
              appUtils
                .removeVietnameseTones(d.patient_name.toLowerCase())
                .includes(search_string) ||
              (d?.doctor?.name &&
                appUtils
                  .removeVietnameseTones(d?.doctor?.name.toLowerCase())
                  .includes(search_string))
            )
          })
          .sort(compare)
        return result
      } else {
        return null
      }
    },
    mdtDiseases () {
      let data = this.filteredAndSorted
      if (data) {
        return data.filter(d => !d.type)
      } else {
        return []
      }
    },
    tsDiseases () {
      let data = this.filteredAndSorted
      if (data) {
        return data.filter(d => !!d.type)
      } else {
        return []
      }
    },
    isPlayer () {
      return this.usr_member?.role === 4 || this.usr_member?.role === 1
    },
    langId () {
      return appUtils.getLangId(this.$lang || 'vi')
    }
  },
  methods: {
    async showSession () {
      let self = this
      await this.$rf
        .getRequest('DoctorRequest')
        .getPublicSessionDetail(this.$route.params.id)
        .then(r => {
          self.session = r.data
          self.showMdtRoom(r.data.public_mdt_id)
        })
    },
    async showMdtRoom (id) {
      let self = this
      await self.$rf
        .getRequest('DoctorRequest')
        .getPublicMtdRoomDetails(id)
        .then(r => {
          self.room = r.data
          self.usr_member = r?.data?.members?.find(
            m => m.user_id === this.user_id
          )
        })
    },
    isPass (d) {
      return (
        window.moment(d + ' 23:59:59', 'YYYY-MM-DD HH:mm:ss') < window.moment()
      )
    },
    formatDate (date) {
      return moment(date).format('DD/MM/YYYY')
    },
    formatDateDMYHm (dt) {
      return moment(dt).format('DD/MM/YYYY HH:mm')
    },
    backToMdtDetail () {
      this.$router.push({
        name: 'ListMdt',
        query: { mdtid: this.room?.id, type: 'ORG' }
      })
    },
    open (url, e) {
      e.stopPropagation()
      window.open(url)
    },
    print (url, e) {
      e.stopPropagation()
      window.open(url)
    },
    onStartToRoom (id, e) {
      e.stopPropagation()
      this.goToRoom(id)
    },
    goToRoom (id) {
      let r = this.room
      let dm = ''
      if (process.env.NODE_ENV === 'development') {
        dm = 'https://dev.meet.hodo.com.vn/#/'
      } else {
        dm = 'https://meet.hodo.com.vn/#/'
      }
      let token = appUtils.getLocalToken()
      let routeData = dm + '_pmdt/' + r?.room?.room_name + '?token=' + token
      if (id) routeData += '&showing_id=' + id
      window.open(routeData, '_blank')
    },
    goToMdtDisease (item) {
      this.$router.push({
        name: 'OrganizationMdtDiseaseDetail',
        params: { id: item.id }
      })
    },
    gotoPrint () {
      const mdtSessionId = this.$route.params.id
      this.$router.push({
        name: 'OrganizationCasePrinter',
        params: { id: mdtSessionId }
      })
    },
    patientDiagnostic (data) {
      const mdt_room_disease = data?.mdt_room_disease
      if (data.patient_diagnostic) {
        return data.patient_diagnostic
      }

      if (mdt_room_disease?.multilang?.length) {
        const langId = this.langId
        const r = mdt_room_disease?.multilang.find(
          item => item.lang === langId
        )
        if (!r) return mdt_room_disease?.patient_diagnostic
        else return r.patient_diagnostic
      }
      return mdt_room_disease?.patient_diagnostic
    }
  }
}
</script>

<style scoped>
.upsidedown {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}
</style>
